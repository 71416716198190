import React, { useEffect, useState } from "react";
import {
  useGetUserCoursesQuery,
  useGetUsersQuery,
  useSearchUserQuery,
} from "../../services/users";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";
import moment from "moment";

const UsersTable = ({ isSearch, searchQuery }) => {
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const { data: usersList } = useGetUsersQuery(
    {
      page: page,
      per_page: 50,
    },
    { refetchOnMountOrArgChange: true }
  );

  const { data: searchedUsers } = useSearchUserQuery(
    {
      searchQuery: searchQuery,
      page: page,
      per_page: 50,
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (isSearch) {
      setTableData(searchedUsers);
    } else {
      setTableData(usersList);
    }
  }, [usersList, searchedUsers]);

  useEffect(() => {
    setPage(1);
  }, [isSearch, searchQuery]);

  return (
    <>
      <div
        style={{
          width: "100%",
          maxHeight: "calc(100vh - 300px)",
          overflow: "auto",
        }}
      >
        <Table className="table table-admin">
          <Thead className="table-header">
            <Tr>
              <Th className="font_12 font-color-grey font-bold">
                اسم المستخدم
              </Th>
              <Th className="font_12 font-color-grey font-bold">
                البريد الالكتروني
              </Th>
              <Th className="font_12 font-color-grey font-bold">
                تدرب بلا حدود
              </Th>
              <Th className="font_12 font-color-grey font-bold">عدد الدورات</Th>
              <Th className="font_12 font-color-grey font-bold">
                تاريخ الالتحاق بتدرب
              </Th>
              {/* <Th className="font_12 font-color-grey font-bold">
              المزيد من التفاصيل
            </Th> */}
            </Tr>
          </Thead>
          <Tbody>
            <>
              {tableData?.data?.map((user) => (
                <Tr key={user.id}>
                  <Td>
                    <Link to={`/users/${user.id}/personal-details`}>
                      <div className="item me-2">
                        <span
                          className="bold font-extrabold font_14"
                          // style={{ marginRight: 10 }}
                        >
                          <img
                            src={user.avatar_url}
                            alt={user.name}
                            width={30}
                            height={30}
                            style={{
                              borderRadius: 50,
                              marginLeft: 10,
                            }}
                          />
                          {user.name}
                        </span>
                      </div>
                    </Link>
                  </Td>
                  <Td>
                    <div className="item">
                      <span className="font_14">{user.email}</span>
                    </div>
                  </Td>
                  <Td>
                    <div className="item">
                      <span className="font_14">
                        {user.subscriptions.length > 0
                          ? `مشترك حتى ${user.subscriptions[0].renewal_date}`
                          : "غير مشترك"}
                      </span>
                    </div>
                  </Td>
                  <Td>
                    <div className="item">
                      <span className="font_14" style={{ marginRight: 10 }}>
                        {user?.courses_number}
                      </span>
                    </div>
                  </Td>
                  <Td>
                    <div className="item">
                      <span
                        className="font_14"
                        style={{
                          marginRight: 10,
                        }}
                      >
                        {/* {user?.name} */}
                        {moment(user.created_at).format("YYYY-MM-DD")}
                      </span>
                    </div>
                  </Td>
                </Tr>
              ))}
            </>
          </Tbody>
        </Table>
      </div>
      {tableData?.data?.length > 1 && (
        <Pagination
          current={tableData?.pagination?.current}
          pages={tableData?.pagination?.pages}
          handlePagination={(page) => setPage(page)}
        />
      )}
    </>
  );
};

export default UsersTable;
