import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../components/Navbar";
import SideBar from "../components/SideBar";
import SideBarMobile from "../components/SideBarMobile";
import { getNavbarItems, getSidebarItems } from "./navigationItems";
import { setCurrentUser } from "../app/store/reducers/authSlice";
import { toast } from "react-toastify";
import { useLogoutMutation } from "../services/auth";

const Layout = ({ activeNavbar, activeSidebar }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.auth?.currentUser)

  const [logout] = useLogoutMutation();

  const handleLogout = async () => {
    const result = await logout();
    if (!result.error || result?.error?.status === 401) {
      localStorage.removeItem("token");
      dispatch(setCurrentUser(null));
      navigate("/");
    } else {
      toast.error(result?.error?.data?.message);
    }
  };

  return (
    <div className="admin-layout">
      <Navbar
        items={getNavbarItems(activeNavbar)}
      // oraganizationLogo={myProfile?.data ? myProfile.data.image_url : ""}
      ></Navbar>
      <div className="container-fluid">
        <div className="row">
          {activeSidebar && (
            <SideBar
              items={getSidebarItems(activeSidebar, currentUser?.is_admin)}
              handleLogout={handleLogout}
            />
          )}

          <SideBarMobile
            items={getNavbarItems(activeNavbar)}
            additionalItems={getSidebarItems(activeSidebar, currentUser?.is_admin)}
            handleLogout={handleLogout}
          />
          <div className="col content-page">
            {/* This element will render child of AdminLayout */}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
