import { useState, useEffect } from "react";
import Checkbox from "../components/Inputs/Checkbox";
import Icon from "../components/Icon/Icon";
import Spinner from "../components/Spinner";
import v from "../scss/helpers/_export-theme-variables.scss";
import {
  useShowProfileQuery,
  useUpdateProfileMutation,
} from "../services/auth";
import { toast } from "react-toastify";
import BlogSettings from "./TagsAndTopics";

const Settings = () => {
  const [allowSubscriptionPlansChecked, setAllowSubscriptionPlansChecked] =
    useState(false);
  const [allowSystemAdsChecked, setAllowSystemAdsChecked] = useState(false);
  const [submmiting, setSubmitting] = useState(false);

  const { data: profile, isSuccess, isLoading } = useShowProfileQuery();
  const [updateProfile] = useUpdateProfileMutation();

  useEffect(() => {
    if (isSuccess) {
      setAllowSubscriptionPlansChecked(profile?.data?.allow_subscription_plans);
      setAllowSystemAdsChecked(profile?.data?.allow_system_ads);
    }
  }, [isSuccess]);

  const handleSubmittingSettings = async () => {
    const result = await updateProfile({
      allow_subscription_plans: allowSubscriptionPlansChecked,
      allow_system_ads: allowSystemAdsChecked,
    });
    if (!result?.error) {
      toast.success("تم الحفظ بنجاح");
    } else {
      toast.error(result?.error?.data?.message);
    }
  };

  if (isLoading) return;

  return (
    <>
      <BlogSettings />
      <div className="settings layout-pages">
        <h1 className="font_24 font-extrabold">الإعدادات</h1>
        <hr />
        <Checkbox
          children="أوافق على المشاركة في الاعلانات والحملات الترويجية الخاصة بالموقع"
          id="agree"
          checked={allowSystemAdsChecked}
          setChecked={setAllowSystemAdsChecked}
          labelClassName="font-color-grey-700 font-extrabold"
          variant="green"
          error={
            <>
              <Icon
                iconName="exclamation-circle-stroke"
                iconSize={16}
                iconColor={v.colorRed}
                isStroke
                className="me-2"
              />
              ايقاف الاعلانات والحملات الترويجية قد يؤثر سلباً على مبيعاتك
              الشهرية
            </>
          }
        />

        <hr />

        <Checkbox
          children="أوافق على المشاركة في ميزة الاشتراك في هذا الموقع ومشاركة الإيرادات"
          id="agreeSubscribe "
          checked={allowSubscriptionPlansChecked}
          setChecked={setAllowSubscriptionPlansChecked}
          labelClassName="font-color-grey-700 font-extrabold"
          variant="green"
        />
        <button
          type="submit"
          className="btn btn-main-color btn-size-lg mb-3 mb-md-0 mx-auto mx-md-0 mt-48"
          disabled={submmiting}
          onClick={handleSubmittingSettings}
        >
          حفظ البيانات
          {submmiting && (
            <Spinner
              style={{ marginRight: "10px", width: "20px", height: "20px" }}
            />
          )}
        </button>
      </div>
    </>
  );
};

export default Settings;
